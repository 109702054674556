import React from "react";
import { createPortal } from "react-dom";
import { Redirect } from "react-router-dom";
import { authStates, withAuth } from "../components/auth";
import { signOut } from "../utils/firebase";
import Loader from "../components/loader";
import { withRouter } from "react-router-dom";
import { STATUS_COLORS } from '../components/constants';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import AddPaymentMethod from '../components/AddPaymentMethod';
import PaymentForm from '../components/PaymentForm';

import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore'; // Ensure Firestore is imported correctly

import CreateCircle from "./create-circle";
import JoinCircle from "./join-circle";
import CircleView from "./circle-view";
import VerifyGoal from "./verify-goal";
import LearnMore from "./learn-more";

// stripe key
const stripePromise = loadStripe('pk_live_51P6402GSxt9qdcRfx1JHdTt8CoVcxPAQvjFGJuTUZl1XoUsjy02SPkTvwocsrgXyyiBZBS5vvrtaFVHQL83JaY3V00lckKDKjS');

const STATE_ENUM = {
  PAFU: 0,
  PAYMENT: 1
}

const navOptions = [
  {
    name: "PAFU",
    value: STATE_ENUM.PAFU
  },
  {
    name: "Payment",
    value: STATE_ENUM.PAYMENT
  }
]

class Home extends React.Component {


  componentDidUpdate(prevProps) {
    // Check if user object has now been populated and was not available previously
    if (this.props.user && !prevProps.user) {
        console.log("Userrr: ", this.props.user);
        this.fetchUserCircles();
    }
  }
  
  fetchUserCircles = () => {
    const db = firebase.firestore();
    const userRef = db.collection("users").doc(this.props.user.uid);
  
    userRef.get().then((doc) => {
      if (doc.exists) {
        const circlesIds = doc.data().circles; // Assuming 'circles' is an array of circle IDs
        this.fetchCirclesDetails(circlesIds);
      }
    }).catch(error => {
      console.error("Error fetching user's circles: ", error);
    });
  };
  fetchCirclesDetails = (circlesIds) => {
    const db = firebase.firestore();
    const promises = circlesIds.map(circleId => 
      db.collection("circles").doc(circleId).get()
        .then(doc => {
          if (!doc.exists) {
            console.log(`No such document with id ${circleId}!`);
            return null; // Skip this document and filter it out later
          }
          return doc;
        })
        .catch(error => {
          console.error(`Error getting document with id ${circleId}: `, error);
          return null; // Skip this document and filter it out later
        })
    );
  
    Promise.all(promises).then(circlesSnapshot => {
      // Filter out any null results from errors or non-existent documents
      const validDocs = circlesSnapshot.filter(doc => doc !== null);
  
      const goalsData = validDocs.map(doc => {
        const circleData = doc.data();
        const userHistory = circleData.userData.find(u => u.userId === this.props.user.uid)?.userHistory ?? [];
        const circleId = doc.id;


        return {
          name: circleData.goal, // The goal of the circle
          status: userHistory,
          circleId: circleId,
        };
  
      });
  
      this.setState({ goals: goalsData });
    }).catch(error => {
      console.error("Error fetching circles details: ", error);
    });
  };
  


  state = {
    goals: [
      // { name: 'Wake up early', status: [0, 1, 2, 1, 0, 1, 2] },
      // { name: 'no fap', status: [1, 2, 0, 1, 2, 1, 2] },
      // ... other goals
    ],
    // ... other state variables
    tabState: STATE_ENUM.PAFU,
    createCircle: false,
    joinCircle: false,
    circleView: false,
    learnMore: false,

    verifyGoalOpen: false,
    currentVerifyCircleId: null,
  };

  handleSignOut = () => {
    signOut()
      .then(() => {
        console.log("Signed Out");
      })
      .catch(e => {
        console.log("Error signing out", e);
      });
  };

  navigateToCreateCircle = () => {
    // this.props.history.push('/create-circle');
    this.setState({ createCircle: true });
  };

  navigateToJoinCircle = () => {
    // this.props.history.push('/join-circle');
    this.setState({ joinCircle: true });
  };

  navigateToCircleView = (circleId) => {
    console.log("navigateToCircleView: ", circleId)
    // this.props.history.push(`/circle-view/${circleId}`);
    this.setState({ circleView: true, currentCircleId: circleId });
  };

  openVerifyGoal = (circleId) => {
    this.setState({
      verifyGoalOpen: true,
      currentVerifyCircleId: circleId
    });
  };
  


  renderDashboard = () => {
    return (
      <div>
        <div className="goals-grid w-full md:w-[70%] ">
          {this.state.goals.map((goal, index) => (
            <div key={index} className="goal relative">
              <div className="goal-name">{goal.name}</div>
              <div className="goal-status w-1/2 md:w-auto overflow-x-auto md:overflow-x-visible">
                {goal.status.map((status, statusIndex) => (
                  <div key={statusIndex} className={`status-dot ${STATUS_COLORS[status]}`}></div>
                ))}
              </div>
              {/* Check if the last status is 2 and display the Verify button */}
              {goal.status[goal.status.length - 1] === 2 && (
                <button onClick={(e) => {
                  e.stopPropagation(); // Prevents the navigateToCircleView from firing
                  this.openVerifyGoal(goal.circleId);
                }} className="verify-button absolute right-0 md:-right-20">
                  Verify
                </button>
              )}
              <div className="goal-view cursor-pointer ml-5"  onClick={() => this.navigateToCircleView(goal.circleId)}>
                <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" class="w-8 h-8" fill="none" stroke="currentColor">
                  <path d="M20.56,18.44l-4.67-4.67a7,7,0,1,0-2.12,2.12l4.67,4.67a1.5,1.5,0,0,0,2.12,0A1.49,1.49,0,0,0,20.56,18.44ZM5,10a5,5,0,1,1,5,5A5,5,0,0,1,5,10Z"/>
                </svg>
              </div>
            </div>
          ))}
        </div>
        <div className="flex justify-center items-center h-full space-x-4"> {/* Adjust space-x-* for desired spacing */}
            <div className="flex items-center space-x-1"> {/* Use space-x-2 for spacing between dot and text */}
              <div className="status-dot green"></div>
              <span>Pass</span>
            </div>
            <div className="flex items-center space-x-1">
              <div className="status-dot red"></div>
              <span>Fail</span>
            </div>
            <div className="flex items-center space-x-1">
              <div className="status-dot grey"></div>
              <span>Skip</span>
            </div>
            <div className="flex items-center space-x-1">
              <div className="status-dot blue"></div>
              <span>New</span>
            </div>
          </div>
      </div>
    );
  };
  
  
  renderSwitch = () => {
    switch(this.state.tabState) {
      case STATE_ENUM.PAFU:
        return this.renderDashboard();
      case STATE_ENUM.PAYMENT:
        return (
        <Elements stripe={stripePromise}>
          <AddPaymentMethod />
        </Elements>
        );
      default:
        return this.renderDashboard();
    }
  }

  onCircleCreated = () => {
    this.setState({ createCircle: false }); // Close the modal
    this.fetchUserCircles(); // Optionally refresh circles list or navigate
  };

  onCircleLeft = () => {
    this.setState({ circleView: false }); // Close the modal
    this.fetchUserCircles(); // Refresh circles list or navigate
  };

  render() {
    const { authState, user } = this.props;

    if (authState === authStates.INITIAL_VALUE) {
      return <Loader />;
    }

    if (authState === authStates.LOGGED_OUT) {
      return <Redirect to="/login" />;
    }

    const modalRoot = document.getElementById('modal-root');

    const { learnMore } = this.state;


    // const setIsCreateOpen = (isOpen) => {
    //   this.setState({ createCircle: isOpen })
    // }

    // const setIsJoinOpen = (isOpen) => {
    //   this.setState({ joinCircle: isOpen })
    // }

    // const setIsCircleOpen = (isOpen) => {
    //   this.setState({ circleView: isOpen })
    // }

    return (
    <div className="background flex flex-col justify-center items-center z-0 relative">
      <div className="flex flex-col w-full p-4 md:w-1/2 min-h-2/5 h-3/5 md:h-2/5 relative">
        <div className="absolute -top-20 flex flex-row items-center w-full">
          <div className="w-20 h-20 rounded-full bg-red-400"/>
            <div className="flex justify-center items-center h-full">
              {/* Trigger button for Learn More modal */}
              <button
                onClick={() => this.setState({ learnMore: true })}
                className="button absolute right-10 md:right-20">
                Learn More!!
              </button>

              {/* Portal for Learn More modal */}
              {this.state.learnMore && createPortal(
                <LearnMore isOpen={this.state.learnMore} onClose={() => this.setState({ learnMore: false })} />,
                document.getElementById('modal-root')
              )}
            </div>
          </div>

        <div className="flex-1 bg-secondary rounded-md px-4 md:px-16 py-4">
          <div className="w-full flex flex-row mb-4">
            {
              navOptions.map(option => (
                <div
                  key={option.value}
                  onClick={() => this.setState({ tabState: option.value })}
                  className={`mr-4 p-2 border-0 border-b-2 cursor-pointer hover:border-b-acccent
                  ${this.state.tabState === option.value ? 'text-white border-b-accent ' : 'text-gray-600 border-b-gray-600 '}
                  duration-300`}
                >
                  {option.name}
                </div>
              ))
            }
          </div>
          
          {this.renderSwitch()}
          {this.state.createCircle && createPortal(<CreateCircle isOpen={this.state.createCircle} setIsOpen={(isOpen) => this.setState({ createCircle: isOpen })}onCircleCreated={this.onCircleCreated}/>, document.getElementById('modal-root'))}
          {this.state.joinCircle && createPortal(<JoinCircle isOpen={this.state.joinCircle} setIsOpen={(isOpen) => this.setState({ joinCircle: isOpen })}/>, modalRoot)}
          {this.state.circleView && createPortal(<CircleView isOpen={this.state.circleView} circleId={this.state.currentCircleId} setIsOpen={(isOpen) => this.setState({ circleView: isOpen })}onCircleLeft={this.onCircleLeft}/>, document.getElementById('modal-root'))}
          {this.state.verifyGoalOpen && createPortal(<VerifyGoal isOpen={this.state.verifyGoalOpen} circleId={this.state.currentVerifyCircleId} setIsOpen={(isOpen) => this.setState({ verifyGoalOpen: isOpen })}/>, modalRoot)}
        </div>
        <div className="flex flex-row justify-center items-center md:px-8">
          <button className="icon-button" onClick={this.navigateToCreateCircle}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-8 h-8">
              <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
            </svg>
          </button>
          <button className="icon-button" onClick={this.navigateToJoinCircle}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M18 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0ZM3 19.235v-.11a6.375 6.375 0 0 1 12.75 0v.109A12.318 12.318 0 0 1 9.374 21c-2.331 0-4.512-.645-6.374-1.766Z" />
            </svg>
          </button>
          <button className="icon-button" onClick={this.handleSignOut}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0 0 13.5 3h-6a2.25 2.25 0 0 0-2.25 2.25v13.5A2.25 2.25 0 0 0 7.5 21h6a2.25 2.25 0 0 0 2.25-2.25V15m3 0 3-3m0 0-3-3m3 3H9" />
            </svg>
          </button>
        </div>
      </div>
    </div>
    );
  }
}

export default withRouter(withAuth(Home));
