import React from 'react';
import { createPortal } from 'react-dom';

const FAQ = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  const modalContent = (
    <div className='w-screen h-screen flex justify-center items-center bg-black bg-opacity-60'>
        <div className='bg-white w-3/5 rounded-md overflow-hidden'>
            <div className='flex justify-between items-center p-4 bg-white sticky top-0 z-10'>
            <h1>Frequently Asked Questions</h1>
            <button onClick={onClose} className='text-lg font-semibold'>X</button>
            </div>
            <div className='p-4 overflow-y-auto max-h-[calc(80vh-80px)]'>
            <ul>
          <li className="mb-2">
            <strong>Where does my money go?</strong>
            <p>All profit from the beta testing period (i.e. our revenue minus funds needed to keep our website and app running) will go to The American Red Cross and Doctors without Borders, and receipts will be sent to all users.</p>
          </li>
          <li className="mb-2">
            <strong>I want to start, but won't I lose a lot of money?</strong>
            <p>You can commit as little as $1 per habit. If you fail to meet your goal 10 times in a month, you'd only lose $10--you lose less than the cost of a couple coffees, but with the potential to transform a habit you've been stuck with for years.</p>
          </li>
          <li className="mb-2">
            <strong>How is my card information handled?</strong>
            <p>We don’t store any card information. We leverage Stripe to handle all payments.</p>
          </li>
          <li className="mb-2">
            <strong>What happens if I want out?</strong>
            <p>You can leave any Pafu circle at any time with the click of one button.</p>
          </li>
          <li className="mb-2">
            <strong>How does Pafu verify that I complete my tasks?</strong>
            <p>Pafu doesn’t! Your friends do. When you tell a Pafu circle you’ve completed a task, your friends in this circle vet the evidence you submit.</p>
          </li>
          <li className="mb-2">
            <strong>Can’t I cheat the system by submittting fake evidence and telling my friends to allow it?</strong>
            <p>Yes.</p>
          </li>
        </ul>
            </div>
        </div>
    </div>
  );

  return createPortal(modalContent, document.getElementById('modal-root'));
};

export default FAQ;
