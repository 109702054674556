import React, { useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import firebase from 'firebase/compat/app';
import 'firebase/compat/functions';

const AddPaymentMethod = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [paymentMethodSaved, setPaymentMethodSaved] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    if (!stripe || !elements) {
      setError("Please try again later.");
      return;
    }

    // Call Firebase Function to create a Setup Intent
    const createSetupIntent = firebase.functions().httpsCallable('createSetupIntent');
    const { data } = await createSetupIntent();
    const { clientSecret } = data;

    const cardElement = elements.getElement(CardElement);

    const result = await stripe.confirmCardSetup(clientSecret, {
      payment_method: {
        type: 'card',
        card: cardElement
      },
    });

    if (result.error) {
      setError(result.error.message);
      setLoading(false);
      console.log("Error while saving payment method.");
    } else {
        const savePaymentMethodFn = firebase.functions().httpsCallable('savePaymentMethod');
        console.log('payment method: ', result.setupIntent.payment_method)
        await savePaymentMethodFn({
            paymentMethodId: result.setupIntent.payment_method,
        });
        // Payment method was successfully saved
        console.log("Payment Method saved:", result.setupIntent.payment_method);
      setLoading(false);
      setError(null);
      setPaymentMethodSaved(true);
    }
  };

  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: "#000000",  // Set your desired color here
        fontSize: "16px",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        "::placeholder": {
          color: "#222222"
        }
      },
      invalid: {
        color: "#e5424d",  // Color when there are errors
        "::placeholder": {
          color: "#FFCCA5"
        }
      }
    }
  };

  return (
    <div className="max-w-md mx-auto bg-white shadow-md rounded-md">
      <div className="p-6">
        <h2 className="text-2xl font-semibold text-center mb-4 text-gray-800">Add Payment Method</h2>
        <p className="text-center text-gray-600 mb-4">We use Stripe to securely save your payment method.</p>
        {!paymentMethodSaved ? (
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <p className="text-left text-gray-600">Enter your card details</p>
              <CardElement options={CARD_ELEMENT_OPTIONS} className="p-2 border border-gray-300 rounded-md"/>
            </div>
            <button 
              className='w-full mt-4 bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 transition duration-300 disabled:bg-gray-400 disabled:cursor-not-allowed' 
              disabled={!stripe || loading}
            >
              {loading ? 'Processing...' : 'Save Payment Method'}
            </button>
            {loading && (
              <div className="text-blue-600 mt-2 text-center">
                Please wait while we securely process your payment method with Stripe...
              </div>
            )}
            {error && <div className="text-red-600 mt-2 text-center">{error}</div>}
          </form>
        ) : (
          <div className="text-center">
            <p className="text-green-600 mb-4">Your payment method has been successfully saved!</p>
            <p className="mb-4 text-gray-800">Download the Pafu app to start on your habit formation journey.</p>
            <div className="flex justify-center space-x-4">
              <a 
                href="https://testflight.apple.com/join/4z90YaPv" 
                target="_blank" 
                rel="noopener noreferrer"
                className="bg-black text-white px-4 py-2 rounded-md hover:bg-gray-800 transition duration-300"
              >
                Download for iOS
              </a>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AddPaymentMethod;

