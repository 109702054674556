import React, { Component } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { withRouter } from 'react-router-dom';
import { withAuth } from '../components/auth';
import { STATUS_COLORS } from '../components/constants';

class CircleView extends Component {
  state = {
    circle: null,
    usersData: [],
    circleId: this.props.circleId,
    showModal: false,
    selectedImageUrl: null, // To store the image URL to be displayed
    currentUserIndex: null,
    currentStatusIndex: null,
    selectedOption: null, // New state to track the selected radio option
  };
  

  componentDidMount() {
    if (this.props.circleId) {
      this.setState({ circleId: this.props.circleId });
      this.fetchCircleData(this.state.circleId);
    }
  }


  fetchCircleData = (circleId) => {
    const db = firebase.firestore();
    db.collection("circles").doc(circleId).get().then((doc) => {
      if (doc.exists) {
        this.setState({ circle: doc.data() }, () => {
          this.fetchUsernames();
        });
      } else {
        console.log("No such circle!");
      }
    }).catch((error) => {
      console.error("Error fetching circle data: ", error);
    });
  };

  fetchUsernames = () => {
    const { circle } = this.state;
    const db = firebase.firestore();
    const userRefs = circle.userData.map(user => db.collection("users").doc(user.userId));

    Promise.all(userRefs.map(ref => ref.get())).then(userDocs => {
      const usersData = userDocs.map((userDoc, index) => {
        if (userDoc.exists) {
          const username = userDoc.data().username; // Assuming the user's document contains 'username'
          return {
            username,
            history: circle.userData[index].userHistory,
            proofUrl: circle.userData[index].proofUrl,
            userId: circle.userData[index].userId // Ensure userId is correctly assigned here

          };
        } else {
          return null;
        }
      }).filter(Boolean); // Filter out null entries

      this.setState({ usersData });
    }).catch((error) => {
      console.error("Error fetching usernames: ", error);
    });
  };

  renderUserHistories = () => {
    const { usersData } = this.state;
  
    return (
      <div className="users-grid">
        {usersData.map((user, userIndex) => (
          <div key={userIndex} className="user-history">
            <div className="username">{user.username}</div>
            <div className="user-statuses">
              {user.history.map((status, statusIndex) => (
                <div key={statusIndex} 
                     className={`status-dot ${STATUS_COLORS[status]} cursor-pointer`} 
                     onClick={() => this.showImageModal(user.proofUrl[statusIndex], userIndex, statusIndex)}
                     title={`Status: ${status}`}> {/* Optionally add a title for accessibility */}
                  {/* Removed {status} from being displayed inside the div */}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    );
  };
  

  resetStatus = () => {
    const { usersData, currentUserIndex, currentStatusIndex, circleId, selectedOption } = this.state;
    if (currentUserIndex == null || currentStatusIndex == null || selectedOption == null) return;
    
    const newUsersData = [...usersData];
    const newUserHistory = [...newUsersData[currentUserIndex].history];
    
    newUserHistory[currentStatusIndex] = parseInt(selectedOption);
    newUsersData[currentUserIndex].history = newUserHistory;
    
    // Update the state first with the new users data and close the modal
    this.setState({ usersData: newUsersData, showModal: false }, () => {
      // After state is updated, update Firestore
      this.updateUserHistoryInFirestore(circleId, newUsersData[currentUserIndex].userId, newUserHistory);
    });
  };
  
  
  updateUserHistoryInFirestore = (circleId, userId, newHistory) => {
    const db = firebase.firestore();
    const circleRef = db.collection("circles").doc(circleId);

    console.log("Updating user history in Firestore...", circleId, userId, newHistory);
  
    db.runTransaction(transaction => {
      return transaction.get(circleRef).then(doc => {
        if (!doc.exists) {
          throw new Error("Document does not exist!");
        }
        const newUserData = doc.data().userData.map(user => {
          if (user.userId === userId) {
            // Update userHistory for the specific user, keeping other properties unchanged
            return {...user, userHistory: newHistory};
          }
          // else {
          //   console.log("User not found in circle's userData: ", user.userId, userId)
          // }
          return user;
        });
  
        // Set the new userData
        transaction.update(circleRef, { userData: newUserData });
      });
    }).then(() => {
      console.log("User history updated successfully.");
    }).catch(error => {
      console.error("Error updating user history in Firestore: ", error);
    });
  };
  
  


  showImageModal = (imageUrl, userIndex, statusIndex) => {
    console.log("Showing image modal for URL: ", imageUrl);
    // if (!imageUrl) {
    //   return;
    // }
    this.setState({
      showModal: true,
      selectedImageUrl: imageUrl,
      currentUserIndex: userIndex,
      currentStatusIndex: statusIndex
    });
  };
  

  renderImageModal = () => {
    const { showModal, selectedImageUrl, selectedOption } = this.state;
    if (!showModal) return null;
  
    // Handler for closing the modal if clicked outside the modal content
    const handleCloseModal = (event) => {
      if (event.currentTarget === event.target) {  // Checks if the click is directly on the modal container, not its children
        this.setState({ showModal: false });
      }
    };
  
  
    // Define a function to handle option selection and status reset
    const handleOptionClick = (optionValue) => {
      this.setState({ selectedOption: optionValue }, () => {
        this.resetStatus(); // Call resetStatus after the state update
      });
    };
  
    return (
      <div className="image-modal" style={{ position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(0, 0, 0, 0.5)' }} onClick={handleCloseModal}>
        <div className="modal-content" style={{ position: 'relative', backgroundColor: '#FFF', padding: '20px', borderRadius: '8px', width: '50%', maxWidth: '600px' }} onClick={(e) => e.stopPropagation()}>
          <img src={selectedImageUrl} alt="Verification" style={{ width: '100%', height: 'auto', padding: '10px', maxWidth: '450px' }} />
          <div style={{ marginTop: '20px', textAlign: 'center'}} className="options">
            <button className={`button m-1 ${selectedOption === "3" ? "selected" : ""}`} onClick={() => handleOptionClick("3")}>Skip</button>
            <button className={`button m-1 ${selectedOption === "1" ? "selected" : ""}`} onClick={() => handleOptionClick("1")}>Verify</button>
            <button className={`button m-1 ${selectedOption === "0" ? "selected" : ""}`} onClick={() => handleOptionClick("0")}>Deny</button>
          </div>
        </div>
      </div>
    );
  };
  
  
  
  
  
  removeUserFromCircle = () => {
    const { user } = this.props;
    const { circleId } = this.state;
    const db = firebase.firestore();
  
    // Remove circleId from user's circles attribute
    const userRef = db.collection("users").doc(user.uid);
    userRef.update({
      circles: firebase.firestore.FieldValue.arrayRemove(circleId)
    })
    .then(() => {
      console.log("Circle removed from user's circles list.");
    })
    .catch((error) => {
      console.error("Error removing circle from user's list: ", error);
    });
  
    // Fetch circle data
    const circleRef = db.collection("circles").doc(circleId);
    circleRef.get().then(doc => {
      if (doc.exists) {
        const circleData = doc.data();
        const newUserData = circleData.userData.filter(userItem => userItem.userId !== user.uid);
  
        // Update the circle's userData with the filtered array
        circleRef.update({
          userData: newUserData
        })
        .then(() => {
          console.log("User removed from circle's userData.");
          this.props.onCircleLeft();  // Navigate back or trigger other UI update
        })
        .catch((error) => {
          console.error("Error updating userData in circle: ", error);
        });
      } else {
        console.error("Circle not found");
      }
    }).catch(error => {
      console.error("Error fetching circle data: ", error);
    });
  };
  

  render() {
    const { isOpen, setIsOpen } = this.props;
    if (!isOpen) return null;
  
    return (
      <div className='w-screen h-screen flex justify-center items-center bg-black bg-opacity-60'>
        <div className='bg-white w-3/5 rounded-md p-8'>
          <button onClick={() => setIsOpen(false)} className='float-right'>X</button>
          <h1>{this.state.circle?.name}</h1>
          <h2>{this.state.circle?.goal}</h2>
          <p>Click a square to see the evidence your friends have presented in the past.</p>
          <div className="circle-view">
            {this.state.circle && this.renderUserHistories()}
          </div>
          <button onClick={this.removeUserFromCircle} className="remove-button" disabled={!this.state.circleId}>
            Leave Circle
          </button>
          {this.renderImageModal()}
        </div>
      </div>
    );
  }  

}

export default withRouter(withAuth(CircleView));
