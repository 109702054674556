import React from "react";
import ReactDOM, { createPortal } from 'react-dom'; // Correct import for createPortal
import { Link, Redirect } from "react-router-dom";
import { authStates, withAuth } from "../components/auth";
import en from "../utils/i18n";
import Loader from "../components/loader";
import { signIn, createNewUser } from "../utils/firebase";
import { validateEmailPassword } from "../utils/helpers";
import Typist from 'react-typist';
import "../styles/login.css";
import LearnMore from "./learn-more";
import FAQ from "./faq"; // Import the new FAQ component


class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      retype: "",
      error: "",
      websiteHook: true,
      isLogin: false,
      showLearnMore: false,  // State to control the Learn More modal
      showFAQ: false,  // State to control the FAQ modal
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value,
      error: "",
    });
  }

  handleSubmit(event) {
    event.preventDefault();

    if (this.state.error) {
      return;
    }

    //Validate email & password
    const errorMsg = validateEmailPassword(
      this.state.email,
      this.state.password
    );

    if (errorMsg) {
      this.setState({
        error: errorMsg,
      });
      return;
    }

    if(this.state.isLogin){
      signIn(this.state.email, this.state.password)
      .then(() => {
        console.log("Signed In");
      })
      .catch(e => {
        console.log("Error signing in", e);
        this.setState({
          error: "Incorrect email/password",
        });
  });
  }
    else {
      createNewUser(this.state.username, this.state.email, this.state.password)
      .then(() => {
        console.log("Signed Up!");
      })
      .catch(e => {
        console.log("Error signing up", e);
        if (e.code === "auth/email-already-in-use") {
          this.setState({
            error: "Email already in use",
          });
        } else if (e.code === "auth/weak-password") {
          this.setState({
            error: "Password should be at least 6 characters",
          });
        } else {
          this.setState({
            error: "An error occurred during sign up. Please try again.",
          });
        }
      });
    }
  }

  alternateState = (login) => {
    this.setState({
      isLogin: login,
    });
  }

  toggleLearnMore = () => {
    this.setState({ showLearnMore: !this.state.showLearnMore });
  }

  toggleFAQ = () => {
    this.setState({ showFAQ: !this.state.showFAQ });
  }

  render() {
    if (this.props.authState === authStates.INITIAL_VALUE) {
      return <Loader />;
    }
    else if (this.props.authState === authStates.LOGGED_IN && 
             this.state.websiteHook) {
      console.log("Entering website hook mode.")
      return <Redirect to="/payment"></Redirect>;
    }
    // if (this.props.authState === authStates.LOGGED_IN) {
    //   return <Redirect to="/"></Redirect>;
    // }
    const errorMsg = this.state.error;
    const { showLearnMore, showFAQ } = this.state;
    const modalRoot = document.getElementById('modal-root');

    return (
      <div className="background flex flex-col md:flex-row md:items-center">
        <div className=" md:flex-1 w-full h-full flex flex-col md:justify-center items-center md:items-end mt-12 px-4 md:pr-8">
            <div className="flex flex-col md:items-start md:w-1/2 md:text-left">
              <h1 className="text-black text-8xl font-bold">{en.APP.TITLE}</h1>
              <p className="text-black text-2xl">
                <Typist cursor={{ show: true }} avgTypingDelay={40} stdTypingDelay={40}>
                  {en.APP.DESC}
                </Typist>
              </p>
            </div>
        </div>
        <form onSubmit={this.handleSubmit} className="md:flex-1 form-container md:pl-8">
          <div className="flex flex-col items-center md:items-start w-1/2">

            {
              this.state.isLogin ? "" : (
                <input
                  type="text"
                  placeholder={en.FORM_FIELDS.USERNAME}
                  name="username"
                  onChange={this.handleInputChange}
                  required
                />
              )
            }

            <input
              type="text"
              placeholder={en.FORM_FIELDS.EMAIL}
              name="email"
              onChange={this.handleInputChange}
              required
            />

            <input
              type="password"
              placeholder={en.FORM_FIELDS.PASSWORD}
              name="password"
              onChange={this.handleInputChange}
              required
            />

            {
              this.state.isLogin ? "" : (
                <input
                  type="password"
                  placeholder={en.FORM_FIELDS.RETYPE_PASSWORD}
                  name="retype"
                  onChange={this.handleInputChange}
                  required
                />
              )
            }

            {errorMsg && <p className="error">{errorMsg}</p>}
            <button id="login-button" type="submit" className="text-black mt-2 md:mt-4">
              {this.state.isLogin ? "Login" : "Sign Up"}
            </button>

            {
              this.state.isLogin ? (<p>{en.FORM_FIELDS.LOGIN_ALT_TEXT} <span className="link" onClick={() => {this.alternateState(false)}}>Sign up</span>.</p>)
              : <p className="text-black">Already a member? <span className="link" onClick={() => {this.alternateState(true)}}>Login</span>.</p>
            }

            <div className="w-full flex justify-center mt-12 space-x-4">
              <button onClick={this.toggleLearnMore} className="button">
                Learn More
              </button>
              <button onClick={this.toggleFAQ} className="button">
                FAQ
              </button>
            </div>
            <p className="text-center text-sm mt-12">
              Contact us: <a href="mailto:contact@example.com" className="text-blue-600 hover:underline">trymypafu@gmail.com</a>
            </p>
          </div>
        </form>


        {/* Portal for Learn More Modal */}
        {showLearnMore && createPortal(
          <LearnMore isOpen={showLearnMore} onClose={this.toggleLearnMore} />,
          modalRoot
        )}

        {/* Portal for FAQ Modal */}
        {showFAQ && createPortal(
          <FAQ isOpen={showFAQ} onClose={this.toggleFAQ} />,
          modalRoot
        )}
      </div>
    );
  }
}

export default withAuth(Login);
