import React, { Component } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore'; // Ensure Firestore is imported correctly
import { withRouter } from 'react-router-dom';
import { withAuth } from '../components/auth';

class CreateCircle extends Component {

  state = {
    name: '',
    goal: '',
    password: '',
    penalty: '',
    daysOfWeek: {
      Mon: true,
      Tue: true,
      Wed: true,
      Thu: true,
      Fri: true,
      Sat: true,
      Sun: true
    }
  };
  handleGoalChange = (e) => {
    this.setState({ goal: e.target.value });
  };

  handleNameChange = (e) => {
    this.setState({ name: e.target.value });
  };

  handlePasswordChange = (e) => {
    this.setState({ password: e.target.value });
  };

  handlePenaltyChange = (e) => {
    this.setState({ penalty: parseInt(e.target.value, 10) });
  };

  toggleDay = (day) => {
    this.setState(prevState => ({
      daysOfWeek: {
        ...prevState.daysOfWeek,
        [day]: !prevState.daysOfWeek[day]
      }
    }));
  };

  handleCreateCircle = (e) => {
    e.preventDefault();
    if (!this.props.user) {
      console.error("User must be logged in to create a circle.");
      return;
    }
  
    const { name, goal, password, penalty, daysOfWeek } = this.state;
    const selectedDays = Object.entries(daysOfWeek).filter(([day, isSelected]) => isSelected).map(([day]) => day);
    const db = firebase.firestore();
    const newCircle = {
      name,
      goal,
      password, // Consider security implications
      penalty,
      daysOfWeek: selectedDays,
      userData: [
        {
          startDate: new Date().toISOString(),
          userHistory: [2],
          proofUrl: [""],
          userId: this.props.user.uid
        }
      ]
    };

    db.collection("circles").add(newCircle)
      .then((docRef) => {
        console.log("Circle created with ID: ", docRef.id);
        return db.collection("users").doc(this.props.user.uid).update({
          circles: firebase.firestore.FieldValue.arrayUnion(docRef.id)
        });
      })
      .then(() => {
        console.log("User's circle list updated.");
        this.props.onCircleCreated(); // Call the callback passed by the parent component
      })
      .catch((error) => {
        console.error("Error adding circle or updating user: ", error);
      });
  };
  

  render() {

    const { isOpen, setIsOpen } = this.props;

    if(!isOpen) return null;

    return (
      <div className='w-screen h-screen flex justify-center items-center bg-black bg-opacity-60'>
        <div className='bg-white w-3/5 rounded-md p-8'>
          <button onClick={() => setIsOpen(false)} className='float-right'>X</button>
          <h1>Create a New Circle</h1>
          <form onSubmit={this.handleCreateCircle}>
            <div>
              <label>Circle Name</label>
              <input
                type="text"
                value={this.state.name}
                onChange={this.handleNameChange}
                required
              />
            </div>
            <div>
              <label>Goal</label>
              <input
                type="text"
                value={this.state.goal}
                onChange={this.handleGoalChange}
                required
              />
            </div>
            <div>
              <label>Password (Optional)</label>
              <input
                type="password"
                value={this.state.password}
                onChange={this.handlePasswordChange}
                required
              />
            </div>
            <div>
              <label>Penalty</label>
              <input
                type="number"
                value={this.state.penalty}
                onChange={this.handlePenaltyChange}
                required
              />
            </div>
            <div>
              <label>Days of the Week</label>
              <div>
                {Object.keys(this.state.daysOfWeek).map(day => (
                  <button key={day} type="button" onClick={() => this.toggleDay(day)}
                          className={`m-1 ${this.state.daysOfWeek[day] ? 'bg-red-500 text-white' : 'bg-gray-200'}`}>
                    {day}
                  </button>
                ))}
              </div>
            </div>
            <button type="submit">Create Circle</button>
          </form>
        </div>
      </div>
    );
  }
}

export default withRouter(withAuth(CreateCircle));
