import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

// if (!process.env.REACT_APP_FIREBASE_CONFIG) {
//   console.error("REACT_APP_FIREBASE_CONFIG must be defined");
//   console.log("ENV: ", process.env);
// }
const firebaseConfig = {
  apiKey: "AIzaSyDi9_Q95PWE65niqyNRaZCkxKmFEfosKUQ",
  authDomain: "pafu-4542a.firebaseapp.com",
  projectId: "pafu-4542a",
  storageBucket: "pafu-4542a.appspot.com",
  messagingSenderId: "815553322823",
  appId: "1:815553322823:web:d16fe6e6e73d8eb4bdf662",
  measurementId: "G-B5FK8CB8KV"
};
export function initialize() {
  // Initialize Firebase
  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
  }
}

export function attachAuthListener(handler) {
  return firebase.auth().onAuthStateChanged(user => {
    handler(user);
  });
}

// export async function createNewUser(username, email, password) {

//   await firebase.auth().createUserWithEmailAndPassword(email, password);
// }

export async function createNewUser(username, email, password) {
  try {
    // Create user with email and password in Firebase Authentication
    const userCredential = await firebase.auth().createUserWithEmailAndPassword(email, password);
    
    // Get the user ID from the created user
    const userId = userCredential.user.uid;

    // Add a document in Firestore in the 'users' collection with the new user's details
    await firebase.firestore().collection('users').doc(userId).set({
      username: username,
      email: email,
      circles: []  // Initialize an empty array for circles
    });

    console.log('User created and added to Firestore successfully.');
  } catch (error) {
    console.error('Error creating new user: ', error);
    throw error;  // Optionally re-throw to handle errors outside of this function
  }
}

export async function signIn(email, password) {
  await firebase.auth().signInWithEmailAndPassword(email, password);
}

export async function signOut() {
  await firebase.auth().signOut();
}
