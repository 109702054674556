import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withAuth } from '../components/auth';

class LearnMore extends Component {
  render() {
    const { isOpen, onClose } = this.props; // Use onClose instead of setIsOpen

    if (!isOpen) return null;

    return (
      <div className='w-screen h-screen flex justify-center items-center bg-black bg-opacity-60'>
        <div className='bg-white w-full sm:w-4/5 md:w-3/5 rounded-md overflow-hidden'>
          <div className='flex justify-between items-center p-4 bg-white sticky top-0 z-10'>
            <h1>Learn More:</h1>
            <button onClick={onClose} className='text-lg font-semibold'>X</button>
          </div>
          <div className='p-4 overflow-y-auto max-h-[calc(80vh-80px)]'>
            <div className="modal-content w-full flex justify-center items-center mb-4" style={{ position: 'relative', paddingBottom: '56.25%', height: 0 }}>
              <iframe
                src="https://www.loom.com/embed/d7868f6417c84cdba7d63ac095a49711"
                allow="fullscreen"
                className="loom-video"
                title="loom-tutorial-video"
                style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', border: 'none', borderRadius: '8px' }}
              ></iframe>
            </div>
            <h2>Welcome to Pafu</h2>
            <p>Sticking to new habits is a challenge faced by many. Traditional habit trackers often rely on a single psychological principle, which may not sustain long-term commitment. Our founders experienced this firsthand, experimenting with various habit tracking methods over the course of a year, only to find that these methods fell short in maintaining long-term adherence. At Pafu, we understand that a more robust approach is needed to not only break bad habits but to ensure they stick for the long haul.</p>
            <p>By integrating three powerful commitment devices—financial disincentives, social pressure, and gamification—Pafu creates a comprehensive system that significantly enhances motivation and engagement. This unique combination not only supports users in overcoming the initial resistance to habit formation but also fosters sustained commitment, ensuring that you can maintain your new habits over time.</p>  
            <br></br>   
            <h2>Financial Disincentives</h2>
            Financial disincentives, particularly the concept of losing money for not completing a task, harness the psychological principle of loss aversion to encourage behavioral changes. Loss aversion, a cornerstone of Prospect Theory, posits that the pain of losing is psychologically about twice as powerful as the pleasure of gaining <a href="https://www.jstor.org/stable/1914185">(Kahneman & Tversky, 1979)</a>. This principle can effectively be applied to behavior modification through financial disincentives.<br /><br />

            One compelling application of this is seen in the realm of education, where Fryer et al. (2012) explored how the threat of having to return a portion of money previously given could enhance the efficacy of teacher incentives. They found that teachers under this "loss" condition performed significantly better, suggesting that the possibility of losing money already received increased their commitment to achieving specified goals <a href="https://www.nber.org/papers/w18237">(Fryer et al., 2012)</a>.<br /><br />

            Similarly, in the healthcare sector, financial disincentives have been used to encourage health-promoting behaviors. For instance, a study by Volpp et al. (2008) demonstrated that smokers were more likely to quit smoking when faced with the risk of losing a deposit if they failed to meet their cessation goals. This approach effectively leverages the aversion to financial loss to drive significant changes in behavior <a href="https://pubmed.ncbi.nlm.nih.gov/19066383/">(Volpp et al., 2008)</a>.<br /><br />

            The underlying mechanism here is that the fear of financial loss creates a strong incentive to modify behavior to avoid the negative outcome. When individuals are aware that they stand to lose something of value, they are more likely to engage in the desired behavior to prevent that loss. This is particularly effective when the financial stakes are meaningful to the individual, reinforcing the behavior change over time.<br /><br />
          
            <h2>Social Pressure</h2>
            Social pressure, particularly the influence exerted by peers, plays a significant role in motivating individuals to engage in desired behaviors and to avoid laziness. This phenomenon is rooted in the fundamental human need for social approval and fear of social disapproval, which can be powerful motivators for behavior modification.<br /><br />

            The influence of social pressure on behavior has been extensively documented in psychological research. Cialdini and Goldstein (2004) emphasize that the desire to be liked and accepted by others can significantly influence an individual’s actions, often leading them to conform to the social norms and expectations of their peer group <a href="https://psycnet.apa.org/record/2004-10385-021">(Cialdini & Goldstein, 2004)</a>. This need for social approval encourages individuals to perform tasks diligently and to a high standard to maintain or enhance their status within the group.<br /><br />

            Further support for the impact of social pressure on behavior comes from Bandura’s (1986) social learning theory, which posits that individuals learn and replicate behaviors observed in others, particularly those they consider peers or role models <a href="https://psycnet.apa.org/record/1985-98423-000">(Bandura, 1986)</a>. The anticipation of positive social reinforcement or the fear of negative social evaluation can drive individuals to exert more effort and avoid behaviors perceived as lazy.<br /><br />

            Moreover, in a workplace context, Lount Jr. and Wilk (2014) found that individuals are more likely to engage in productive and diligent behavior when they are aware they are being observed by colleagues. The presence of peers heightens individuals' perception of being evaluated, which in turn increases their motivation to perform well <a href="https://psycnet.apa.org/record/2014-19841-002">(Lount Jr. & Wilk, 2014)</a>.<br /><br />
          
            <h2>Gamification</h2>
            Gamification, the application of game-design elements and principles in non-game contexts, effectively increases engagement and participation in various activities. By integrating game mechanics such as streaks and points into tasks, gamification enhances motivation and enhances the likelihood of task completion.<br /><br />

            Research in educational settings has demonstrated the power of gamification to increase student engagement and improve learning outcomes. Domínguez et al. (2013) conducted a study in which an educational course was gamified, and results showed that students not only participated more actively but also achieved higher academic performance compared to those in a traditional course setup <a href="https://www.researchgate.net/publication/256194365_Gamifying_Learning_Experiences_Practical_Implications_and_Outcomes">(Domínguez et al., 2013)</a>. This suggests that the motivational boost provided by gamification can lead to increased dedication and effort in educational tasks.<br /><br />

            In the context of health, Hamari and Koivisto (2015) found that gamified elements could significantly increase physical activity. Their study highlighted that the use of gamified applications for exercise led to increased exercise frequency and intensity among participants, primarily due to the enjoyment and challenge provided by the game elements <a  href="https://psycnet.apa.org/record/2015-25761-034">(Hamari & Koivisto, 2015)</a>. This underscores gamification’s ability to transform mundane activities into engaging and enjoyable experiences, thereby promoting healthier lifestyles.<br /><br />

            Furthermore, in the workplace, gamification has been shown to enhance employee productivity and job satisfaction. A study by Suh, Wagner, and Liu (2017) revealed that when job-related tasks were gamified, employees reported higher levels of job satisfaction and were more productive <a href="https://www.semanticscholar.org/paper/The-Effects-of-Game-Dynamics-on-User-Engagement-in-Suh-Wagner/5c8ab52e986fbccb82dd12cf07f4a9efbe9854e0">(Suh, Wagner, & Liu, 2017)</a>. This improvement is attributed to the clear goals, immediate feedback, and sense of achievement that gamification introduces, making work more engaging and rewarding.
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(withAuth(LearnMore));
