import React, { Component } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { withRouter } from 'react-router-dom';
import { withAuth } from '../components/auth';

// // The Cloud Functions for Firebase SDK to create Cloud Functions and triggers.
// const {logger} = require("firebase-functions");
// const {onRequest} = require("firebase-functions/v2/https");
// const {onDocumentCreated} = require("firebase-functions/v2/firestore");


class JoinCircle extends Component {
  state = {
    circleName: '',
    password: '',
  };

  handleCircleNameChange = (e) => {
    this.setState({ circleName: e.target.value });
  };

  handlePasswordChange = (e) => {
    this.setState({ password: e.target.value });
  };

  handleJoinCircle = (e) => {
    e.preventDefault();
    const { circleName, password } = this.state;
    const db = firebase.firestore();
  
    db.collection("circles")
      .where("name", "==", circleName)
      .where("password", "==", password)
      .get()
      .then(querySnapshot => {
        if (!querySnapshot.empty) {
          const circle = querySnapshot.docs[0];
          const circleId = circle.id;
          const circleData = circle.data();
  
          // Check if the user is already in the circle
          const isAlreadyMember = circleData.userData.some(user => user.userId === this.props.user.uid);
          if (isAlreadyMember) {
            throw new Error('User already a member of this circle.');
          }
  
          const userData = {
            startDate: new Date().toISOString(),
            userHistory: [2],
            proofUrl: [""],
            userId: this.props.user.uid,
          };
  
          // Add user to circle's userData
          circle.ref.update({
            userData: firebase.firestore.FieldValue.arrayUnion(userData)
          });
  
          // Add circle ID to user's circles
          return db.collection("users").doc(this.props.user.uid).update({
            circles: firebase.firestore.FieldValue.arrayUnion(circleId)
          });
        } else {
          throw new Error('Circle not found or password incorrect.');
        }
      })
      .then(() => {
        console.log("User added to circle successfully.");
        this.props.history.push('/');
      })
      .catch((error) => {
        console.error("Error joining circle: ", error);
        alert(error.message); // Display error message to the user
      });
  };
  

  render() {
    // return (
    //   <div>

    //   </div>
    // );

    const { isOpen, setIsOpen } = this.props;

    if(!isOpen) return null;

    return (
      <div className='w-screen h-screen flex justify-center items-center bg-black bg-opacity-60'>
        <div className='bg-white w-3/5 rounded-md p-8'>
          <button onClick={() => setIsOpen(false)} className='float-right'>X</button>
          <h1>Join a Circle</h1>
          <form onSubmit={this.handleJoinCircle}>
            <div>
              <label>Circle Name</label>
              <input
                type="text"
                value={this.state.circleName}
                onChange={this.handleCircleNameChange}
                required
              />
            </div>
            <div>
              <label>Password</label>
              <input
                type="password"
                value={this.state.password}
                onChange={this.handlePasswordChange}
                required
              />
            </div>
            <button type="submit">Join Circle</button>
          </form>
        </div>
      </div>
    );
  }
}

export default withRouter(withAuth(JoinCircle));


