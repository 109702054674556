import React, { Component } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';  // Import if you're going to use Firebase Storage
import { withRouter } from 'react-router-dom';
import { withAuth } from '../components/auth';

class VerifyGoal extends Component {
  state = {
    file: null,  // This will store the file object
    uploading: false,
    error: null
  };

  handleFileChange = (e) => {
    this.setState({ file: e.target.files[0] });
  };

  handleVerifyGoal = (e) => {
    e.preventDefault();
    const { file } = this.state;
    const { circleId, user } = this.props; // Assume user and circleId are passed as props
  
    if (!file) {
      console.error("Please select a file to upload.");
      return;
    }
  
    const storageRef = firebase.storage().ref();
    const timestamp = new Date().getTime();
    const fileRef = storageRef.child(`${user.uid}/${circleId}/${timestamp}`);
    this.setState({ uploading: true });
  
    fileRef.put(file).then(() => {
      console.log("Uploaded a file!");
      fileRef.getDownloadURL().then((url) => {
        console.log("File available at", url);
        // Here you can also update Firestore with the URL if needed
        this.updateCircleData(circleId, user.uid, url);
        this.setState({ uploading: false });
      });
    }).catch((error) => {
      console.error("Error uploading file: ", error);
      this.setState({ error: error.message, uploading: false });
    });
  };
  
  updateCircleData = (circleId, userId, imageUrl) => {
    const db = firebase.firestore();
    const circleRef = db.collection("circles").doc(circleId);
  
    db.runTransaction((transaction) => {
      return transaction.get(circleRef).then((doc) => {
        if (!doc.exists) {
          throw new Error("Document does not exist!");
        }
  
        const userData = doc.data().userData;
        const userIndex = userData.findIndex(u => u.userId === userId);
        if (userIndex === -1) {
          throw new Error("User not found in circle!");
        }
  
        // Update proofUrl and userHistory arrays
        const newUserHistory = [...userData[userIndex].userHistory];
        newUserHistory[newUserHistory.length - 1] = 1; // Change last history to 1
  
        // Replace last element of proofUrl with new image URL
        const newProofUrl = [...userData[userIndex].proofUrl];
        if (newProofUrl.length > 0) {
          newProofUrl[newProofUrl.length - 1] = imageUrl; // Replace last item
        } else {
          newProofUrl.push(imageUrl); // The array was empty, so push the new URL
        }
  
        // Update only the specific user's data
        userData[userIndex].userHistory = newUserHistory;
        userData[userIndex].proofUrl = newProofUrl;
  
        // Commit the update to Firestore
        transaction.update(circleRef, { userData });
      });
    }).then(() => {
      console.log("Circle data updated successfully.");
    }).catch((error) => {
      console.error("Transaction failed: ", error);
    });
  };
  

  render() {
    const { uploading, error } = this.state;
    const { isOpen, setIsOpen } = this.props;

    if (!isOpen) return null;

    return (
      <div className='w-screen h-screen flex justify-center items-center bg-black bg-opacity-60'>
        <div className='bg-white p-4 rounded-md'>
          <button onClick={() => setIsOpen(false)} className='float-right'>X</button>
          <h1>Verify Goal</h1>
          <form onSubmit={this.handleVerifyGoal}>
            <div>
              <label htmlFor="file">Upload Photo:</label>
              <input
                type="file"
                id="file"
                onChange={this.handleFileChange}
                required
              />
            </div>
            {uploading && <p>Uploading...</p>}
            {error && <p style={{ color: 'red' }}>{error}</p>}
            <button type="submit" disabled={uploading}>Submit Verification</button>
          </form>
        </div>
      </div>
    );
  }
}

export default withRouter(withAuth(VerifyGoal));
